import { useFonts } from "expo-font";
import "expo/build/Expo.fx";
import React, { FC, useCallback, useState } from "react";
import { Platform, ScrollView } from "react-native";
import { Navigate, Route, Routes } from "react-router-native";
import { Aurora, Text } from "@lookiero/aurora";
import { EventProvider } from "@lookiero/event";
import { EndpointFunction } from "@lookiero/i18n";
import { i18n } from "@lookiero/i18n-react";
import { PaymentsQueryProvider, setPaymentsBridge } from "@lookiero/payments-front";
import { fetchTranslations, translationExternalEndpoint } from "@lookiero/sty-psp-i18n";
import { Country, Locale } from "@lookiero/sty-psp-locale";
import { SentryEnvironment } from "@lookiero/sty-psp-logging";
import { Segment } from "@lookiero/sty-psp-segment";
import { DummyLayout } from "@lookiero/sty-psp-ui";
import { KameleoonEnvironment } from "./infrastructure/ab-testing/kameleoonEnvironment";
import { bootstrap as checkoutBootstrap } from "./infrastructure/delivery/bootstrap";
import { bootstrap as checkoutMockBootstrap } from "./infrastructure/delivery/bootstrap.mock";
import { root } from "./infrastructure/ui/Root";
import { Router } from "./infrastructure/ui/routing/router/Router";
import { Customer } from "./projection/customer/customer";
import { Order } from "./projection/order/order";
import { Subscription } from "./projection/subscription/subscription";
import { VERSION } from "./version";

const locale: Locale = Locale.es_ES;
const subscription: Subscription = "b";
const order: Order = {
  isFirstOrder: false,
  orderNumber: 3687582,
  coupon: "MYLOOKIERO",
};

const customer: Customer = {
  customerId: "9413fe3a-09fc-4264-905e-040bb7b4ca75",
  country: Country.ES,
  segment: Segment.WOMEN,
};

const sentryConfig: SentryEnvironment = {
  publicKey: "66cadf9444db4ea5945670f12ec08ae7",
  release: VERSION,
  project: "4504400729276416",
  environment: `${Platform.OS}-EXPO`,
};

const apiUrl =
  Platform.OS !== "web"
    ? "https://web2.sp.dev.aws.lookiero.es/checkout/api"
    : __DEV__
      ? "/local-to-dev"
      : "/checkout/api";
const authToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjU2NTIwMjgsImV4cCI6MTczOTQ0MTYwMSwiZGlzcGxheU5hbWUiOiJNaWtlbCIsImNvdW50cnlfY29kZSI6IkVTIiwiYWNjZXNzVmlhIjoiZW1haWwiLCJzdWJzY3JpcHRpb25TdGFydGluZ0RhdGUiOiIyMDI0LTExLTE4IiwiaW1wZXJzb25hdGVkIjpmYWxzZSwidXVpZCI6Ijk0MTNmZTNhLTA5ZmMtNDI2NC05MDVlLTA0MGJiN2I0Y2E3NSIsImlhdCI6MTczNjc2MzIwMn0.ZaFu4743f9XR0aK3LgMMhbmRHNloHtC1Sm0FDUtj8B8";
const getAuthToken = () => Promise.resolve(authToken);

const externalTranslationsUrl =
  Platform.OS !== "web"
    ? "https://backend-for-user.dev.envs.lookiero.tech/api/v2/translations"
    : __DEV__
      ? "/local-to-external-i18n"
      : "/external-i18n";
const translations: EndpointFunction[] = [
  (locale) =>
    translationExternalEndpoint({
      translationsUrl: externalTranslationsUrl,
      projects: [["user-area-front"], ["inventory-catalog", "feature;feature_value;Color"], ["checkout"]],
    })(locale),
];

const useRedirect: () => Record<string, string> = () => ({
  returnUrl: "https://web2.dev.aws.lookiero.es/user/",
});

setPaymentsBridge({
  getToken: getAuthToken,
  stripeKey: "pk_test_6dwdUfkUm7AaUH3oSje9H0kk",
  stripeUkKey:
    "pk_test_51Jw6NEHQ1gSUXaQ8KKBLBhmzw7k0TCTN0GQ6qq8oGRmXCn09ptn7zrHX5jsyidv8iBDGuzUDV3R0fko01m5oJzMO00z6XmoLbn",
  commonAssetsPath: "https://cdn.dev.envs.lookiero.tech/commons",
  paymentsAssetsPath: "https://cdn.dev.envs.lookiero.tech/payments-front",
  useAvoidKeyboard: () => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAvoidKeyboard: () => void 0,
  }),
  emitTrackingEvent: (e) => {
    console.log("Tracking Payment event", e);
  },
  appVersion: VERSION,
  graphqlUri: "/graphql",
  useFeatureFlags: () => ({}),
  locale: () => Promise.resolve("es-ES"),
  scrollView: ScrollView,
});

const kameleoonConfig: KameleoonEnvironment = {
  siteCode: "aplm4v3ckn",
  experiments: {},
};

const { Component: Messaging } =
  process.env.EXPO_PUBLIC_APP_VARIANT === "test"
    ? checkoutMockBootstrap()
    : checkoutBootstrap({ apiUrl: () => apiUrl, getAuthToken });
const I18n = i18n({
  fetchTranslation: fetchTranslations({ translations }),
  contextId: "CheckoutI18n",
});
const Root = root({
  Messaging,
  I18n,
  getAuthToken,
  development: false,
  sentry: () => (process.env.EXPO_PUBLIC_APP_VARIANT === "test" ? ({} as SentryEnvironment) : sentryConfig),
  kameleoon: () => kameleoonConfig,
})({ customerId: customer.customerId });

const ExpoRoot: FC = () => {
  const [fontsLoaded] = useFonts({
    ["AreaInktrap-Semibold"]: require("@lookiero/aurora-fonts/AreaInktrap-Semibold.otf"),
    ["AreaNormal-Semibold"]: require("@lookiero/aurora-fonts/AreaNormal-Semibold.otf"),
    ["AreaNormal-Extrabold"]: require("@lookiero/aurora-fonts/AreaNormal-Extrabold.otf"),
    auroraicons: require("@lookiero/aurora-iconfont/dist/auroraicons.ttf"),
  });

  const [isAccessible, setIsAccessible] = useState<boolean>();
  const onNotAccessible = useCallback(() => setIsAccessible(false), []);

  return fontsLoaded ? (
    <PaymentsQueryProvider>
      <EventProvider>
        <Aurora>
          {isAccessible === false && <Text heading={true}>Checkout is not accessible!</Text>}

          <Router>
            <Routes>
              <Route
                path="/checkout/*"
                element={
                  <Root
                    basePath="/checkout"
                    customer={customer}
                    layout={DummyLayout}
                    locale={locale}
                    order={order}
                    subscription={subscription}
                    useRedirect={useRedirect}
                    onNotAccessible={onNotAccessible}
                  />
                }
              />

              <Route element={<Navigate to="/checkout" replace />} path="*" />
            </Routes>
          </Router>
        </Aurora>
      </EventProvider>
    </PaymentsQueryProvider>
  ) : null;
};

export { ExpoRoot };
