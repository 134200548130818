import { PortalProvider } from "@gorhom/portal";
import React, { FC } from "react";
import { StatusBar } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { PortalProvider as AuroraPortalProvider } from "@lookiero/aurora";
import { Notifications } from "@lookiero/sty-psp-notifications";
import { theme } from "@lookiero/sty-psp-ui";
import { MESSAGING_CONTEXT_ID } from "../../delivery/baseBootstrap";

const { colorBgBase } = theme();

interface AppProps {
  readonly children: JSX.Element;
}

const App: FC<AppProps> = ({ children }) => (
  <SafeAreaProvider>
    <PortalProvider rootHostName="Checkout">
      <StatusBar backgroundColor={colorBgBase} barStyle="dark-content" translucent />
      <Notifications contextId={MESSAGING_CONTEXT_ID} portalHostName="Checkout" />
      {/*
        We are using the Aurora's PortalProvider at this level for notifications to work properly.

        PaymentInstrumentSelect uses Aurora's Portal, and if we rely on UAF's Portal (injected by <Aurora>)
        notifications would be displayed in a layer below Portal's one (not visible).
      */}
      <AuroraPortalProvider>{children}</AuroraPortalProvider>
    </PortalProvider>
  </SafeAreaProvider>
);

export { App };
