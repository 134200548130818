import React, { FC } from "react";
import { Pressable, View } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { Button, Icon, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { PricingProjection } from "../../../../../../projection/pricing/pricing";
import { Price } from "../../../../components/atoms/price/Price";
import { I18nMessages } from "../../../../i18n/i18n";
import { Pricing } from "../pricing/Pricing";
import { style } from "./CollapsiblePricing.style";

interface CollapsiblePricingProps {
  readonly pricing: PricingProjection;
  readonly totalCheckoutItemsKept: number;
  readonly collapsible?: boolean;
  readonly collapsed?: boolean;
  readonly submitButtonText: string;
  readonly onPress?: () => void;
  readonly onSubmit: () => void;
}
const CollapsiblePricing: FC<CollapsiblePricingProps> = ({
  pricing,
  totalCheckoutItemsKept,
  collapsible = true,
  collapsed = false,
  submitButtonText,
  onPress = () => void 0,
  onSubmit,
}) => {
  const { pendingToPay } = pricing;

  const totalText = useI18nMessage({ id: I18nMessages.SUMMARY_TOTAL });
  const totalCheckoutItemsKeptText = useI18nMessage({
    id: I18nMessages.SUMMARY_TOTAL_ITEMS_KEPT,
    values: { items: totalCheckoutItemsKept.toString() },
  });

  const collapsedOpacity = useSharedValue(collapsed ? 1 : 0);
  collapsedOpacity.value = collapsed ? 1 : 0;
  const collapsedAnimatedStyle = useAnimatedStyle(
    () => ({ opacity: withTiming(collapsedOpacity.value) }),
    [collapsedOpacity.value],
  );
  const notCollapsedOpacity = useSharedValue(collapsed ? 0 : 1);
  notCollapsedOpacity.value = collapsed ? 0 : 1;
  const notCollapsedAnimatedStyle = useAnimatedStyle(
    () => ({ opacity: withTiming(notCollapsedOpacity.value) }),
    [notCollapsedOpacity.value],
  );

  return (
    <>
      {collapsible && (
        <Pressable style={style.iconContainer} testID="pricing-collapsed" onPress={onPress}>
          {collapsed ? (
            <Icon name="arrow_up_small" testID="arrow-up" />
          ) : (
            <Icon name="arrow_down_small" testID="arrow-down" />
          )}
        </Pressable>
      )}

      {collapsed && collapsible ? (
        <Animated.View key="collapsed" style={[style.collapsed, collapsedAnimatedStyle]}>
          <View style={style.collapsedContent}>
            <Text level={1} style={style.totalCollapsed} detail>
              {totalText} {totalCheckoutItemsKeptText}
            </Text>
            <Price price={pendingToPay} variant="detail" />
          </View>

          <View style={style.collapsedContent}>
            <Button testID="submit-checkout-collpased-button" small onPress={onSubmit}>
              {submitButtonText}
            </Button>
          </View>
        </Animated.View>
      ) : (
        <Animated.View key="not-collapsed" style={notCollapsedAnimatedStyle}>
          <Pricing pricing={pricing} totalCheckoutItemsKept={totalCheckoutItemsKept} />

          <Button testID="submit-checkout-button" onPress={onSubmit}>
            {submitButtonText}
          </Button>
        </Animated.View>
      )}
    </>
  );
};

export { CollapsiblePricing };
