import React, { FC, useCallback } from "react";
import { StyleProp, TextStyle, TouchableHighlight, View, ViewStyle } from "react-native";
import { COLOR, Text } from "@lookiero/aurora";
import { Modal, theme } from "@lookiero/sty-psp-ui";
import { style } from "./SelectModal.style";

const { colorBgPrimaryLight } = theme();

interface SelectModalStyle {
  readonly modalContent: StyleProp<ViewStyle>;
  readonly option: StyleProp<ViewStyle>;
  readonly optionText: StyleProp<TextStyle>;
}

interface Option {
  readonly label: string;
  readonly value: string;
}

interface SelectModalProps {
  readonly title?: string;
  readonly value: string | undefined;
  readonly options: Option[];
  readonly style?: Partial<SelectModalStyle>;
  readonly modalVisible: boolean;
  readonly testID?: string;
  readonly portalHostName?: string;
  readonly onChange?: (value: string) => void;
  readonly onClose?: () => void;
}

const SelectModal: FC<SelectModalProps> = ({
  modalVisible,
  options,
  style: customStyle,
  testID,
  title,
  value,
  portalHostName,
  onChange = () => void 0,
  onClose = () => void 0,
}) => {
  const handleOnPressOption = useCallback(
    (value: string) => {
      onChange(value);
      onClose();
    },
    [onClose, onChange],
  );

  return (
    <Modal
      portalHostName={portalHostName}
      testID={testID}
      visible={modalVisible}
      scroll
      showCloseButton
      onClose={onClose}
    >
      <View style={[style.modalContent, customStyle?.modalContent]}>
        {title && (
          <Text level={3} style={style.modalTitle} heading>
            {title}
          </Text>
        )}
        {options.map(({ label, value: optionValue }) => (
          <TouchableHighlight
            key={optionValue}
            accessibilityLabel={optionValue}
            disabled={value === optionValue}
            style={[style.option, customStyle?.option]}
            testID={optionValue}
            underlayColor={colorBgPrimaryLight}
            accessible
            onPress={value !== optionValue ? () => handleOnPressOption(optionValue) : undefined}
          >
            <Text
              color={value === optionValue ? COLOR.TEXT_MEDIUM : COLOR.TEXT}
              level={3}
              style={[style.optionText as ViewStyle, customStyle?.optionText]}
            >
              {label}
            </Text>
          </TouchableHighlight>
        ))}
      </View>
    </Modal>
  );
};

export type { Option };
export { SelectModal };
