import React, { FC, useCallback, useState } from "react";
import { LayoutChangeEvent, StyleProp, TextStyle, View, ViewStyle } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { Text } from "@lookiero/aurora";
import { theme } from "@lookiero/sty-psp-ui";
import { style } from "./Field.style";

const SCALE = 0.75;

const { space1, space4 } = theme();

type FieldStyle = {
  readonly field: StyleProp<ViewStyle>;
  readonly fieldText: StyleProp<TextStyle>;
};

interface FieldProps {
  readonly label: string;
  readonly isFocused?: boolean;
  readonly style?: FieldStyle;
}
const Field: FC<FieldProps> = ({ label, isFocused = false, style: customStyle }) => {
  const [width, setWidth] = useState<number>(0);
  const handleOnLayout = useCallback(({ nativeEvent: { layout } }: LayoutChangeEvent) => setWidth(layout.width), []);

  const scale = useSharedValue(isFocused ? SCALE : 1);
  scale.value = isFocused ? SCALE : 1;
  const translateY = useSharedValue(isFocused ? -14 : space4);
  translateY.value = isFocused ? -14 : space4;
  const translateX = useSharedValue(isFocused ? -(((1 - SCALE) * width) / 2 + space1) : 0);
  translateX.value = isFocused ? -(((1 - SCALE) * width) / 2 + space1) : 0;
  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        { scale: withTiming(scale.value) },
        { translateY: withTiming(translateY.value) },
        { translateX: withTiming(translateX.value) },
      ],
    }),
    [scale.value, translateX.value, translateY.value],
  );

  return (
    <Animated.View pointerEvents="none" style={[customStyle?.field, animatedStyle]} onLayout={handleOnLayout}>
      <Text level={1} numberOfLines={1} style={[style.fieldText, customStyle?.fieldText]} detail>
        {label}
      </Text>
      <View style={[style.fieldBackground, { opacity: isFocused ? 1 : 0 }]} />
    </Animated.View>
  );
};

export type { FieldStyle };
export { Field };
